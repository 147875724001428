@font-face {
  font-family: 'Gilfoy';
  src: local('Gilfoy'), url(../fonts/Gilroy-ExtraBold.otf) format('opentype');
  font-weight: 900;
}
@font-face {
  font-family: 'Gilfoy';
  src: local('Gilfoy'), url(../fonts/Gilroy-Light.otf) format('opentype');
  font-weight: normal;
}
