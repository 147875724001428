html,
body {
    overflow-x: hidden;
}

.navbar-nav-hover {
  .col,
  .container {
      padding-left: 0;
      padding-right: 0;
  }
}

.navbar {
    padding-top: 3.2em;
    padding-bottom: 3.2em;
    .navbar-nav {
        margin-left: auto;
        margin-right: auto;
        .nav-link {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            font-size: 1.2rem;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

h1,
h2 {
    font-size: 3rem;
    line-height: 1.3;
}

img {
    user-select: none;
    pointer-events: none;
}

p {
    font-size: 1.25rem;
    line-height: 1.5;
}

.btn {
    &.disabled:hover {
        box-shadow: none;
        transform: none;
    }
}

.button {
    display: inline-block;
    margin: 0 1rem 1rem 0;
    padding: 0 1.4rem;
    text-transform: uppercase;
    font-size: 0.86rem;
    line-height: 2.3;
    white-space: nowrap;
    color: #2abcf1;
    border: 1px solid #2abcf1;
    border-radius: 1rem;
    background-color: #16192c;
    transition: all 0.2s;
    &:hover {
        color: #2abcf1;
        background-color: #182e45;
    }
}

.header-images {
    z-index: -5;
}

.info-image {
    text-align: center;
}

.header-download-button {
    width: 100%;
    max-width: 9.5rem;
    height: auto;
}

.core-animation-wrap {
    position: relative;
    z-index: 1;
}

.page-header {
    padding-top: 180px;
    h1 {
        margin-bottom: 2.3rem
    }
    p {
        margin-bottom: 2.1rem;
        line-height: 1.2;
    }
}

@media (min-width: 1000px) {
    .header-bg-wrap {
    img {
        &.phone-image {
            max-height: 60vh;
            max-width: 45vw;
            width: auto;
        }
        &.laptop-image {
            max-height: 60vh;
            max-width: 45vw;
            width: auto;
        }
        &.core {
            max-height: 80vh;
            max-width: 45vw;
            width: auto;
        }
    }}
}

@media (max-width: 999px) {
    .header-bg-wrap {
    img {
        &.core {
            width: 69%;
        }
        &.phone-image {
            width: 58.5%;
        }
        &.laptop-image {
            width: 88.5%;
        }
    }}
}

.header-bg-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    .header-gradient-ellipse {
        position: absolute;
        &.ellipse-1 {
            width: 128%;
            padding-top: 128%;
            left: 50%;
            top: 50%;
            transform: translate(-51%, -44%) rotate(30deg);
            background: radial-gradient(50% 50% at 50% 50%, #2ABCF1 0%, rgba(42, 188, 241, 0) 100%);
        }
        &.ellipse-2 {
            width: 85%;
            padding-top: 170%;
            left: 50%;
            top: 50%;
            background: radial-gradient(50% 50% at 50% 50%, rgba(106, 77, 227, 0.48) 0%, rgba(106, 77, 227, 0) 100%);
            transform: translate(-51%, -44%) rotate(60deg);
        }
    }
    img {
        position: absolute;
        &.orbit {
            width: 178%;
            left: 50%;
            top: 50%;
            transform: translate(-63%, -31%);
            -webkit-mask-image: radial-gradient(50% 31% at 64% 31%, black 87%, transparent 100%);
        }
        &.core {
            left: 50%;
            top: 50%;
            transform: translate(-39%, -49%);
        }
        &.phone-image {
            left: 50%;
            top: 50%;
            transform: translate(-38%, -48%);
        }
        &.laptop-image {
            left: 47%;
            top: 50%;
            transform: translate(-42%, -54%);            
        }
    }
}

.download-button {
    background-color:#16192C !important;
    margin-right: 10px;
}

.teamRow {
    justify-content: center;
}

.status-overview {
    padding: 4rem 2rem 11rem 2rem;
    .stats-text {
        font-weight: $font-weight-normal;
        font-size: 6em;
        /* Create the gradient. */
        background-image: linear-gradient(90deg, #2ABCF1 0%, rgb(30, 22, 59) 100%);
        /* Use the text as a mask for the background. */
        /* This will show the gradient as a text color rather than element bg. */
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
    }
    .button {
        display: inline-block;
        padding: 1rem 3rem;
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 2.3;
        white-space: nowrap;
        max-width: 100%;
        color: rgb(30, 22, 59);
        border: 1px solid #2abcf1;
        border-radius: 1rem;
        background-color: #f6f9fc;
        transition: all 0.2s;
        &:hover {
            color: white;
            background: linear-gradient(to bottom right, #000, #145972);
            &:before {
                opacity: 1;
            }
        }
    }
}

.doing-right {
    h2 {
        text-align: center;
    }
    .row {
        margin-bottom: 3rem;
        &:last-child {
            margin-bottom: 10rem;
        }
    }
    .info-image {
        img {
            max-width: 100%;
        }
    }
    .description {
        margin: 7% 11%;
        h5 {
            font-size: 2rem;
        }
    }
}

.security {
    padding: 4rem 0;
    h2 {
        margin: 2rem 1rem;
        text-align: center;
    }
    h5 {
        margin-top: 1.2rem;
        margin-right: 2.5rem;
        margin-bottom: 1.6rem;
        font-size: 1.85rem;
        line-height: 1.3;
    }
    .security-image {
        z-index: -1;
        width: 149%;
    }
    .description {
        padding-bottom: 15%;
    }
}

.build {
    padding: 15rem 0;
    h2 {
        margin-bottom: 3rem;
    }
    .build-bg-wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        .orbit {
            position: absolute;
            width: 245%;
            left: 50%;
            top: 0;
            transform: translate(-66.5%, -31%);
            -webkit-mask-image: radial-gradient(55% 35% at 79% 49%, black 85%, transparent 100%);
        }
        .core-wrap {
            position: absolute;
            width: 97%;
            top: 0;
            left: 50%;
            transform: translate(-47%, 19%);
            .core {
                position: relative;
                width: 100%;
            }
        }
    }
    .build-expandable {
        width: 25rem;
        max-width: 100%;
        margin-bottom: 4.5rem;
        p {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
        }
        .expandable-content {
            color: #91939c;
            font-size: 1rem;
            line-height: 1.3;
        }
    }
}

.compatible {
    position: relative;
    padding: 23rem 0;
    z-index: 1;
    h2 {
        margin-bottom: 2.8rem;
    }
    p {
        line-height: 1.2;
    }
    .btn {
        display: inline-block;
        margin: 0 2.4rem 1.7rem 0 !important;
        box-sizing: border-box;
        font-size: 1rem;
        border: none;
        font-weight: normal;
        text-transform: none;
        line-height: 1.8;
        border-radius: 1.5rem;
        background-color: #1b405b;
        &.btn-white {
            color: white;
        }
        &.btn-info {
            background-color: #227fa8;
        }
    }
    .coin-group {
        position: absolute;
        left: 50%;
        top: 0;
        width: 100%;
        text-align: center;
        transform: translate(-39%, -53%);
    }
    .coin-icon {
        width: 3em;
        margin: 3em 7%;
    }
    .coin-span {
        margin: 0em 7%;
    }
}

.try-it {
    margin-right: 3%;
    padding: 18rem 0 17rem 0;
    .bg-wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        .try-it-gradient-ellipse,
        .try-it-background {
            position: absolute;
            z-index: -5;
        }
        .try-it-gradient-ellipse.ellipse-1 {
            width: 150%;
            padding-bottom: 250%;
            left: 50%;
            top: 50%;
            transform: translate(-55%, -50%);
            background: radial-gradient(50% 50% at 50% 50%, #a4e7ff 0%, rgba(168, 232, 255, 0) 100%);
        }
        .try-it-gradient-ellipse.ellipse-2 {
            width: 165%;
            padding-bottom: 62%;
            left: 50%;
            top: 50%;
            transform: translate(-55%, -50%) rotate(320deg);
            background: radial-gradient(50% 50% at 50% 50%, rgba(166, 144, 255, 0.48) 0%, rgba(173, 152, 255, 0) 100%);
        }
        .orbit {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 212%;
            transform: translate(-33.5%, -49.5%);
            -webkit-mask-image: radial-gradient(55% 35% at 10% 49%, black 85%, transparent 100%);
        }
        .try-it-background {
            user-select: none;
            pointer-events: none;
            mix-blend-mode: overlay;
            position: absolute;
            stroke: white;
            width: 75em;
            transform: translate(-50%, -25%);
        }
    }
    .try-it-phone {
        width: 56%;
        transform: translateX(-4%);
    }
    .try-it-description {
        padding: 5.5% 1%;
        h1 {
            margin-bottom: 3.5rem !important;
        }
        p {
            margin-bottom: 3rem;
        }
    }
}

.team {
    padding: 5em 0 5em 0;
}

.partner {
    padding: 5em 0 5em 0;
    h2 {
        margin-bottom: 3rem;
    }
    .partner-button-div {
        position: relative;
        text-align: right;
    }
    .partner-button-text {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 293px;
        height: 293px;
        border: 5px solid #004159;
        border-radius: 50%;
        text-transform: uppercase;
        font-size: 2rem;
        color: #2F2F2F;
        cursor: pointer;
        transition: all 0.5s;
        &>div {
            z-index: 1;
        }
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            opacity: 0;
            background: linear-gradient(to bottom right, #000, #145972);
            transition: all 0.5s;
        }
        &:hover {
            color: white;
            &:before {
                opacity: 1;
            }
        }
    }
}

.news {
    padding-top: 5rem;
    h2 {
        margin-bottom: 2rem;
    }
    .news-card {
        margin-bottom: 6rem;
        .img-wrap {
            position: relative;
            padding-bottom: 50%;
            overflow: hidden;
            border-radius: 1em;
            background-color: #145972;
            img {
                position: absolute;
                width: 100%;
                height: auto;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
        h5 {
            font-size: 2rem;
        }
        a span {
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: bold;
            text-decoration: underline;
        }
        .news-date {
            font-size: 1.2rem;
            text-align: right;
        }
    }
}

.nav-link:hover, .nav-link.active {
    color: $gray-500;
}

.footer {
    overflow: hidden;
    .footer-bg-wrap {
        position: absolute;
        width: 100%;
        .orbit {
            width: 118%;
            left: 50%;
            top: 50%;
            transform: translate(-7%, -67%);
        }
    }
    .header-download-button {
        margin: 0.25em 0 0.25em 0;
    }
    .navbar-transparent .navbar-nav .nav-link,
    span,
    a {
        color: $white;
    }
    .navbar-transparent .navbar-nav .nav-link:hover {
        color: $gray-500;
    }
    .navbar {
        padding: 0;
        &.navbar-main {
            position: static;
            padding: 2rem 1.5rem;
            z-index: auto;
        }
        .ml-lg-auto,
        .mx-lg-auto {
            margin-left: 0 !important;
        }
    }
    .col,
    .container {
        padding-left: 0;
        padding-right: 0;
    }
    .navbar-main .container {
        margin-left: 0;
        margin-right: 0;
    }
    .footer-links {
        margin: 3.4rem 0.5rem;
        text-transform: uppercase;
        font-size: 1.25rem;
        line-height: 1;
    }
    .footer-download-buttons {
        width: 10rem;
        max-width: 10rem;
        transform: translate(-2rem, -9rem);
        .row {
            flex-direction: column;
            .header-download-button {
                margin-bottom: 1rem;
            }
        }
    }
    .footer-copyrights {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        font-size: 1.23rem;
        transform: translate(0, -4rem);
    }
}

.partner-form {
    .form-wrap {
        position: relative;
        .spinner-wrap {
            display: none;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            background: white;
            .spinner {
                width: 4rem;
                height: 4rem;
            }
        }
        &.in-progress {
            .spinner-wrap {
                display: flex;
            }
        }
    }
    .modal-header {
        text-transform: uppercase;
        font-weight: bold;
    }
    .form-error {
        margin-bottom: 3rem;
        .title {
            margin-bottom: 1rem;
            color: red;
        }
    }
    .form-success {
        margin-bottom: 3rem;
        .title {
            position: relative;
            margin-bottom: 1rem;
            padding-left: 35px;
            color: green;
            img {
                position: absolute;
                left: 0;
                top: 50%;
                width: 20px;
                transform: translateY(-60%);
            }
        }
    }
}

@media (min-width: 1200px)
{
    .container {
        max-width: 1160px;
    }
}

@media (min-width: 1300px)
{
    .container {
        max-width: 1260px;
    }
}

@media (min-width: 1400px)
{
    .container {
        max-width: 1360px;
    }
}

@media (min-width: 1650px) {
    .container {
        max-width: 1610px;
    }
    .security {
        .description {
            padding-top: 23%;
        }
    }
}

@media (max-width: 1650px) {
    .header-bg-wrap {
        width: 150%;
        left: 50%;
        transform: translate(-50%, -15%);
    }
    // .navbar {
    //     .navbar-nav {
    //         .nav-link {
    //             padding-left: 1rem;
    //             padding-right: 1rem;
    //         }
    //     }
    // }
    .footer {
        .footer-download-buttons {
           transform: translate(-2rem, -4rem);
        .row {
            flex-direction: column;
            .header-download-button {
                margin-bottom: 1rem;
            }
        }
    }
}
}

@media (max-width: 1200px) {
    .navbar {
        .navbar-nav {
            .nav-link {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
        }
    }
}

@media (min-width:992px) {
.navbar-nav .nav-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.row-flex {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
}

@media (max-width:992px) {
    h1,
    h2 {
        font-size: 2.5rem;
    }
    .navbar {
        .navbar-nav {
            margin-bottom: 20px;
            .nav-link {
                color: inherit !important;
            }
        }
    }
    
    .page-header {
        align-items: start;
    }
    .header-bg-wrap {
        width: 100%;
        padding-top: 100%;
        transform: translate(-55%, -12%);
    }
    .status-overview {
        padding-top: 10rem;
        .stats-text {
            font-size: 4rem;
        }
    }
    .try-it {
        margin-right: 0;
        margin-bottom: 3rem;
        padding-top: 6rem;
        padding-bottom: 7rem;
        .try-it-row {
            flex-direction: column-reverse;
        }
        .try-it-description {
            padding: 0 1rem 8rem 1rem;
        }
    }
    .partner {
        .partner-button-div {
            padding-top: 5rem;
            text-align: center;
        }
    }
    .build {
        .build-bg-wrap {
            position: relative;
            padding-bottom: 100%;
            transform: translateX(-3%);
        }
    }
    .footer {
        .navbar-toggler {
            display: none;
        }
        .navbar-brand {
            margin-left: auto;
            margin-right: auto;
        }
        .footer-links {
            margin-top: 2rem;
            flex-direction: column;
            ul {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
            }
        }
        .footer-download-buttons {
            width: 100%;
            max-width: 100%;
            margin-top: 2rem;
            margin-bottom: 2rem;
            transform: none;
            .row {
                flex-direction: row;
                justify-content: space-around;
            }
            .header-download-button {
                max-width: 80%;
            }
        }
    }
    .compatible {
        .coin-group {
            padding-top: 8rem;
            padding-bottom: 6rem;
            transform: none;
            position: static;
        }
    }
}

@media (max-width:467px) {
.compatible {
    .coin-icon {
        width: 2.5rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
}}

@media (max-width:414px) {
    h1,
    h2 {
        font-size: 2rem;
    }
    .navbar {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .page-header {
        padding-top: 130px;
        .header-bg-wrap {
            transform: translate(-55%, 5%);
        }
    }
    .status-overview {
        padding-top: 20rem;
        padding-bottom: 6rem;
        .row {
            flex-direction: column;
        }
    }
    .news {
        padding-top: 2rem;
    }
    .compatible {
        .coin-group {
            margin-top: 3rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        .coin-icon {
            width: 2rem;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }
}

.bg-primary p,
.bg-primary h5,
.bg-primary h1,
.bg-primary h2 {
    color: $white;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.expandable-title {
    transition: all 0.5s;
}

.expandable-title.active {
    transform: scaleY(-1);
}

.nav-link {
    cursor: pointer !important;
}

.partner-button-div {
    cursor: pointer !important;
}

.header-image-first {
    animation: headerFadeIn 10s ease infinite;
    animation-timing-function: linear;
}

.header-image-second {
    animation: headerFadeOut 10s ease infinite;
    animation-timing-function: linear;
}

.z-1000 {
    z-index: 1000;
}

@keyframes headerFadeIn {
    0% {
        opacity: 1
    }
    40% {
        opacity: 1
    }
    50% {
        opacity: 0
    }
    90% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes headerFadeOut {
    0% {
        opacity: 0
    }
    40% {
        opacity: 0
    }
    50% {
        opacity: 1
    }
    90% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

// Scroll 
.ball {
    position: absolute;
    top: -5em;
    left: 2em;
    width: 24em;
}

.build .header-background {
    top: -11em;
    left: 5em;
    user-select: none;
    pointer-events: none;
}

@media (max-width:320px) {
    .compatible {
        .coin-icon {
            width: 1.5rem;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }}

@media (min-width: 1650px) {
    .col-xxl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-xxl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
}