.section-footer {
  .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

.footer {
  hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .footer-description {
    padding: 13px;
    font-size: 12px;
    line-height: 2;
    opacity: 0.5;
  }

  .column {

    .logo {
      height: 50px;
      margin-top: 10px;
    }

    h4 {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: .025em;
      margin: 0 0 5px;
      color: #8898aa;
    }

    ul:not(.social-buttons) {
      margin-bottom: 0;
      padding: 0;
      list-style: none;

      li {
        color: #8898aa;
        padding: 5px 0;

        a {
          color: inherit;
          text-decoration: none;
          -webkit-transition: color .1s;
          transition: color .1s;
          font-weight: 500;

          &:hover {
            color: #32325d;
          }
        }
      }
    }
  }

  .copyrights {
    color: #aab7c4;
    list-style: none;
    position: absolute;
    bottom: 5px;
    left: 35px;
  }

  .form-newsletter {
    .form-group {
      float: left;
      width: 78%;
      margin-right: 2%;
      margin-top: 9px;
    }

    .btn {
      position: relative;
      top: 10px;
    }
  }

  &.footer-big {
      padding: 30px 0;

      .social-feed i {
          font-size: 20px;
          float: left;
          padding-right: 10px;
          position: relative;
          top: 3px;
      }

      .gallery-feed img {
          width: 20%;
          margin-right: 5%;
          margin-bottom: 5%;
          float: left;
      }

      hr{
        width: 100%;
      }

    .social-buttons {
      display: inline-flex;
      list-style: none;
      padding-left: 0;

      li {
        margin-right: 10px;
      }
    }

    .column .logo.logo-sm {
      height: 30px;
    }
  }

  &.footer-simple {
    nav,
    .copyright {
      display: inline-block;
      width: 49%;
    }

    .footer-brand {
      padding-left: 15px;
      font-size: $font-size-lg;
      line-height: 60px;
    }

    .inline-menu {
      line-height: 53px;
    }

    .social-buttons {
      line-height: 57px;
    }

    ul {
      margin-bottom: 0;
      padding: 0;
      list-style: none;

      &:not(.links-vertical) {
        line-height: 3.1;
      }

      &.links-vertical {
        line-height: 1.6;

        li {
          display: block;
          margin-left: -5px;
          margin-right: -5px;
          margin-bottom: 0px;

          a {
              padding: 5px;
              display: block;
          }
        }
      }

      li {
          display: inline-block;

          a {
            font-size: $font-size-sm;
            text-transform: uppercase;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
          }
      }
    }
  }

  &[class*="bg-gradient-"] {
    .footer-brand {
      color: $white;
    }

    hr {
      color: rgba(255, 255, 255, 0.3);
    }

    .column h4 {
      color: $white;
    }

    .nav-link,
    ul li a:not(.btn),
    .column p {
      color: rgba(255, 255, 255, 0.7) !important;

      &:hover {
        color: $white !important;
      }
    }
  }


}


.section-shaped {
  .footer {
    .column {
      h4,
      ul li {
        color: $white;
      }
    }
    .copyrights {
      color: $white;
    }
  }
}

@include media-breakpoint-down(sm) {
  footer .title{
    text-align: center !important;
  }

  .footer .btn-wrapper.profile{
    text-align: center !important;
  }
}
